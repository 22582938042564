import { createApp } from 'vue';

import {
  utagLinkHandlerDirective,
  utagLinkHandlerDirectiveName,
} from '@garmin/analytics';
import App from './App.vue';
import router from './router/router.js';
import store from './store/index.js';
import { sentry } from './modules/sentry.js';

// eslint-disable-next-line no-unused-vars
import * as registerComponents from './modules/registerComponents.js';
// eslint-disable-next-line no-unused-vars
import * as registerIcons from './modules/registerIcons.js';

const app = createApp(App);
app.use(store);
app.use(router);
app.directive(utagLinkHandlerDirectiveName, utagLinkHandlerDirective);

// Initialize sentry-client
sentry(app);

app.config.compilerOptions.whitespace = 'preserve';

app.mount('#app');
